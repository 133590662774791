<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="700px">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('analytics.lang_selectProductGroup') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider class="pa-0 ma-0"></v-divider>

      <v-card-text style="overflow-x: auto; max-height: 500px; padding: 0;">
        <v-list>
          <v-list-item @click="selectWaregroup(waregroup)" v-for="waregroup in itemgroups"
                       :key="waregroup.id">
            <v-list-item-content>
              <v-list-item-title>{{ waregroup.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PreSelectItemgroupDialog",

  props: {
    showDialog: Boolean
  },

  data() {
    return {
      itemgroups: []
    }
  },

  watch: {
    async showDialog(val) {
      if(val) {
        this.itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    selectWaregroup(waregroup) {
      this.$emit("selectWaregroup", waregroup);
    }
  }
}
</script>