<template>
    <div>
        <PageTitle :heading="$t('analytic.lang_singleanalyse')" :icon="icon" :subheading="$t('analytic.lang_analytic')"></PageTitle>

        <div class="app-main__inner">
            <Index></Index>
        </div>
    </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import Index from "@/components/analytics/warewanlytics/singleAnalyse/Index";

export default {
    name: "SingleAnalyse",

    components: {
        PageTitle,
        Index
    },

    data: () => ({
        icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
    })
}
</script>