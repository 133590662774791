<template>
  <v-dialog max-width="700px" persistent scrollable v-model="showDialog">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('analytics.lang_selectProduct') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn @click="closeDialog" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="min-height: 100px !important; overflow: hidden">
        <v-text-field :label="$t('erp.lang_nav_waresearch')" dense outlined style="margin-top: 20px;" :loading="loading"
                      v-model="searchWare" @keyup="searchItem"></v-text-field>
      </v-card-text>

      <v-divider class="pa-0 ma-0"></v-divider>

      <v-card-text style="max-height: 500px; padding: 0;" v-if="filteredItems.length > 0">
        <v-list>
          <v-list-item :key="item.id" @click="selectItem(item)" v-for="item in filteredItems">
            <v-list-item-content>
              <v-list-item-title v-if="(item.color.length > 0 && item.size.length > 0)">{{ item.name }}
                ({{ $t('erp.lang_warecreate_color') + ':' + item.color + ' , ' + $t('generic.lang_size') + ':' + item.size }})
              </v-list-item-title>
              <v-list-item-title v-else-if="(item.color.length > 0 && item.size.length == 0)">{{ item.name }}
                ({{ $t('erp.lang_warecreate_color') + ':' + item.color }})
              </v-list-item-title>
              <v-list-item-title v-else-if="(item.color.length == 0 && item.size.length > 0)">{{ item.name }}
                ({{ $t('generic.lang_size') + ':' + item.size }})
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-text v-else>
        <v-alert color="primary" text>{{ $t('generic.lang_thereAreNoItemsAvailable') }}</v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";

export default {
  name: "PreSelectItemDialog",

  props: {
    showDialog: Boolean
  },


  data() {
    return {
      searchWare: "",
      filteredItems: [],
      awaitingSearch:null,
      loading:false,
    }
  },

  watch: {
    async showDialog(val) {
      if(val) {
        this.filteredItems = [];
      }
    },
    searchWare(val) {
      if(val.length > 3)
        this.axios.post(ENDPOINTS.ERP.ITEM["SEARCH2"], {
          query: val
        }).then((res) => {
          this.filteredItems = res.data;
        })
      else
        this.filteredItems = []
    }
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    selectItem(item) {
      this.$emit("selectItem", item);
    },
    searchItem(event) {
      clearTimeout(this.awaitingSearch);
      this.awaitingSearch = setTimeout(() => {
        this.filteredItems = [];
        this.loading=true;
        this.axios
            .post(ENDPOINTS.ERP.ITEM.SEARCH2, {query: event.target.value})
            .then((res) => {
              this.filteredItems = res.data;
            })
            .catch((err) => {
            })
            .finally(() => {
              this.loading=false;
            });
      }, 500);
    },
  }
}
</script>