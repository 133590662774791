<template>
    <v-card>
        <v-card-title class="card-header">
            <div class="card-header-title font-size-lg text-capitalize font-weight-normal">{{ $t('analytics.lang_itemOrSelectWaregroup') }}</div>
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="showItemDialog = true" small>{{ $t('erp.lang_item') }}</v-btn>
                    <v-btn color="primary" @click="showItemgroupDialog = true" small>{{ $t('generic.lang_waregroup') }}</v-btn>
                    <v-btn v-if="selectedItem !== null" color="error" @click="removeSelection" small>{{ $t('generic.lang_deleteSelection') }}</v-btn>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="selectedItem !== null">
            <h4>{{ $t('generic.lang_selected') }}</h4>
            <p>
                <b>
                    <span v-if="selectedItem.type === 'item'">{{ $t('erp.lang_item') }}:</span>
                    <span v-if="selectedItem.type === 'itemgroup'">{{ $t('generic.lang_waregroup') }}:</span>
                </b>
                {{ selectedItem.data.name }}
            </p>
        </v-card-text>

        <PreSelectItemDialog :show-dialog="showItemDialog" @selectItem="itemSelected" @close="showItemDialog = false"></PreSelectItemDialog>
        <PreSelectItemgroupDialog :show-dialog="showItemgroupDialog" @selectWaregroup="itemgroupSelected" @close="showItemgroupDialog = false"></PreSelectItemgroupDialog>
    </v-card>
</template>

<script>
import PreSelectItemDialog from "@/components/analytics/warewanlytics/singleAnalyse/PreSelectItemDialog";
import PreSelectItemgroupDialog from "@/components/analytics/warewanlytics/singleAnalyse/PreSelectItemgroupDialog";

export default {
    name: "PreSelectItemCard",

    components: {
        PreSelectItemgroupDialog,
        PreSelectItemDialog
    },

    props: {
        selectedItem: Object
    },

    data() {
        return {
            showItemDialog: false,
            showItemgroupDialog: false,
        }
    },

    methods: {
        itemSelected(item) {
            this.showItemDialog = false;

            this.$emit("selected", {
                type: 'item',
                data: item
            });
        },
        itemgroupSelected(itemgroup) {
            this.showItemgroupDialog = false;

            this.$emit("selected", {
                type: 'itemgroup',
                data: itemgroup
            });
        },
        removeSelection() {
            this.$emit("removeSelection");
        }
    }
}
</script>