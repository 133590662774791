<template>
    <PreSelect></PreSelect>
</template>

<script>
import PreSelect from "@/components/analytics/warewanlytics/singleAnalyse/PreSelect";

export default {
    name: "Index",

    components: {
        PreSelect
    }
}
</script>